/**
 * This contains functions and configuration related to the key insights component found in Tracker.vue.
 */
import { getMatrixCells, getMatrixChart } from "@/functions/matrix.js";
import { getValueFromObject } from "@/functions/utils.js";
import { GPABands } from "@/apps/fieldBands";
import { getDisplayValue } from "./utils";

export const insights = [
  {
    name: "Postsecondary Plan",
    showInGrades: [11, 12],
    type: "bar",
    options: [
      {
        label: "Confirmed Plan",
        style: "success",
      },
      {
        label: "Unsure",
        style: "info",
      },
      {
        label: "No Plan",
        style: "warning",
      },
    ],
    field: "_planStatus",
  },
  {
    name: "This Quarter On-Track",
    showInGrades: [9, 10, 11, 12],
    type: "bar",
    options: [
      {
        label: "On-Track",
        style: "success",
      },
      {
        label: "Almost On-Track",
        style: "info",
      },
      {
        label: "Off-Track",
        style: "warning",
      },
    ],
    field: "_currentQuarterOnTrackStatus",
  },
  {
    name: "GPA",
    showInGrades: [9, 10, 11, 12],
    type: "bar",
    bands: GPABands,
    field: "GPA",
  },
  {
    name: "ADA & Passing Classes",
    showInGrades: [9, 10, 11, 12],
    type: "matrix",
  },
  {
    name: "G8 Risk & Opportunity",
    showInGrades: [8],
    type: "bar",
    options: [
      {
        label: "High Opportunity",
        style: "success",
      },
      {
        label: "Opportunity",
        style: "gradeB",
      },
      {
        label: "Vulnerable",
        style: "info",
      },
      {
        label: "High Risk",
        style: "warning",
      },
      {
        label: "Missing Academic Data",
        style: "contrast-medium",
      },
      {
        label: "Missing Attendance Data",
        style: "contrast-medium",
      },
    ],
    field: "g8RiskAndOpportunityMarch.riskAndOpportunity",
    // field: "g8RiskAndOpportunityMay.riskAndOpportunity",
  },
  {
    name: "G9 Risk & Opportunity",
    showInGrades: [9],
    type: "bar",
    options: [
      {
        label: "High Opportunity",
        style: "success",
      },
      {
        label: "Opportunity",
        style: "gradeB",
      },
      {
        label: "Vulnerable",
        style: "info",
      },
      {
        label: "High Risk",
        style: "warning",
      },
      {
        label: "Missing Academic Data",
        style: "contrast-medium",
      },
      {
        label: "Missing Attendance Data",
        style: "contrast-medium",
      },
    ],
    field: "g9RiskAndOpportunity.riskAndOpportunity",
  },
];

export const getInsightMatrix = ({ data, config, fields }) => {
  const result = config;
  result.cells = getMatrixCells({ config, data, fields });
  result.chart = getMatrixChart({ config });
  return result;
};

export const getInsightData = ({ data, config, fields }) => {
  const categories = config.bands ? config.bands : config.options;

  const totalRecords = data.length;
  if (!totalRecords) return [];

  let runningTotal = 0; // used to stop the rounded percentages adding up to over 100%

  const myField = fields.find((field) => field.key === config.field);

  return categories.map((element) => {
    const studentCount = data.filter((record) => {
      const value = getValueFromObject(record, config.field);
      return config.bands
        ? //update this logic to reflect current band calculations
          value <= element.high && value >= element.low
        : getDisplayValue({ value, field: myField }) === element.label;
    }).length;

    const myPercent = Math.round((studentCount * 100) / totalRecords);

    runningTotal += myPercent;
    element.percent = runningTotal <= 100 ? myPercent : myPercent - 1;

    return element;
  });
};
